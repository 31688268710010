import { AppName } from "@fanduel/xpg-promohub-library";
import { CANADA_REGIONS, US_REGIONS } from "@/constants";
import Cookies from "js-cookie";

export const debugEnvironments = [
  "development",
  "development-canada",
  "staging",
  "local-dev",
];

export const isNullOrUndefined = (value: any) =>
  value === null || value === undefined;

export const validateQueryParams = (appName, containerId) => {
  if (
    appName &&
    appName != AppName.MOHEGANSUN_CASINO &&
    appName != AppName.FANDUEL_CASINO &&
    appName != AppName.STARDUST_CASINO &&
    appName != AppName.FANDUEL_SPORTSBOOK
  ) {
    return false;
  }

  if (
    containerId &&
    containerId.length > 10 &&
    !(containerId.includes("SBK") || containerId.includes("XSELL"))
  ) {
    return false;
  }
  return true;
};

export const validateAuthToken = (authToken) => {
  const authTokenRegex = /^[A-Za-z0-9_-]+\.[A-Za-z0-9_-]+\.[A-Za-z0-9_-]+$/;
  if (
    !authToken ||
    authToken == "undefined" ||
    !authTokenRegex.test(authToken)
  ) {
    return false;
  }
  return true;
};

export const validateSportsbookRegion = (region) => {
  return (
    region !== null &&
    region != "undefined" &&
    (US_REGIONS.includes(region.toLowerCase()) ||
      CANADA_REGIONS.includes(region.toLowerCase()))
  );
};

export const getBaseUrlBasedOnAppName = (appName) => {
  return appName == AppName.FANDUEL_SPORTSBOOK ? getBaseUrlBasedOnEnv() : null;
};

export const getPapiBaseUrlBasedOnAppName = (appName) => {
  return appName == AppName.FANDUEL_SPORTSBOOK
    ? getPapiBaseUrlBasedOnEnv()
    : null;
};

export const getBaseUrlBasedOnEnv = (env: ImportMetaEnv = import.meta.env) => {
  switch (env.MODE) {
    case "production":
      return env.VITE_APP_API_ENDPOINT;
    case "production-canada":
      return env.VITE_APP_API_ENDPOINT_CANADA;
    case "development":
      return env.VITE_APP_API_ENDPOINT_DEVELOPMENT;
    case "development-canada":
      return env.VITE_APP_API_ENDPOINT_DEVELOPMENT_CANADA;
    case "staging":
      return env.VITE_APP_API_ENDPOINT_STAGING;
    case "local-dev":
      return "";
    default:
      return env.VITE_APP_API_ENDPOINT;
  }
};

export const getPapiBaseUrlBasedOnEnv = (
  env: ImportMetaEnv = import.meta.env
) => {
  switch (env.MODE) {
    case "production":
      return env.VITE_APP_PAPI_ENDPOINT;
    case "development":
      return env.VITE_APP_PAPI_ENDPOINT_DEVELOPMENT;
    case "staging":
      return env.VITE_APP_PAPI_ENDPOINT_STAGING;
    case "production-canada":
    case "development-canada":
    case "local-dev":
      return "";
    default:
      return env.VITE_APP_PAPI_ENDPOINT;
  }
};

export const getBrandBasedOnEnv = (env: ImportMetaEnv = import.meta.env) => {
  switch (env.MODE) {
    case "production":
    case "development":
    case "staging":
    case "local-dev":
      return "FANDUEL";
    case "production-canada":
    case "development-canada":
      return "FANDUEL_CANADA";
    default:
      return "FANDUEL";
  }
};

export const setCookie = (key: string, value: string) => {
  Cookies.set(key, value, {
    secure: false,
    sameSite: "Lax",
    expires: 1,
    domain: getRootDomain(window.location.hostname),
  });
};

export const getRootDomain = (url: string) => {
  const splitReversedUrl = url.split(".").reverse();

  if (splitReversedUrl.length === 1 && splitReversedUrl[0]) {
    return `.${splitReversedUrl}`;
  }

  return splitReversedUrl.length > 1
    ? `.${splitReversedUrl[1]}.${splitReversedUrl[0]}`
    : "";
};
