import { datadogRum } from '@datadog/browser-rum';
import pkg from '../../package.json';

export const initLogging = () => {
  let env;
  switch(window.location.hostname) {
    case Environment.qa:
      env = 'qa_development';
      break;
    case Environment.qa_canada:
      env = 'qa_canada_development';
      break;
    case Environment.cert:
      env = 'cert_staging';
      break;
    case Environment.production:
      env = 'production';
      break;
    case Environment.production_canada:
      env = 'canada_production';
      break;
    default:
      env = 'unknown';
  }

  datadogRum.init({
    applicationId: '969e35cb-4635-40b8-8bac-be54d484cdf6',
    clientToken: 'pubefc90e4f0883594c7cfd04dbe2a8228d',
    site: 'datadoghq.com',
    service: 'xpg-promohub',
    version: pkg.version,
    env: env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    silentMultipleInit: true,
  })
};


enum Environment {
  qa = 'promohub.qa.fndl.dev',
  qa_canada = 'promohub.qa.canada.fndl.dev',
  cert = 'promohub.cert.fndl.dev',
  production = 'promohub.fanduel.com',
  production_canada = 'promohub.fanduel.ca'
}
