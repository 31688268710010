import { useEffect, useMemo, useState } from "react";
import { AppName, AppConfig } from "@fanduel/xpg-promohub-library";
import { usePostMessageListener } from "@/hooks/usePostMessageListener";
import { AppConfigContext } from "@/contexts/AppConfigContext";
import {
  AUTH_TOKEN_COOKIE_NAME,
  LOCAL_STORAGE_KEY,
  defaultRoutes,
  X_REGION_NAME,
  LOCATION_COOKIE_NAME,
} from "@/constants";
import { initLogging } from "@/logging/init-datadog";
import {
  debugEnvironments,
  getBaseUrlBasedOnAppName,
  getBaseUrlBasedOnEnv,
  getPapiBaseUrlBasedOnEnv,
  getBrandBasedOnEnv,
  setCookie,
  validateAuthToken,
  validateQueryParams,
  validateSportsbookRegion,
  getPapiBaseUrlBasedOnAppName,
} from "@/utils/helpers";

export const AppConfigProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [forceRenderKey, setForceRenderKey] = useState(Date.now());
  const envMode = import.meta.env.MODE;

  const [config, setConfig] = useState<AppConfig>({
    appName: AppName.FANDUEL_CASINO,
    baseUrl: getBaseUrlBasedOnEnv(),
    papiBaseUrl: getPapiBaseUrlBasedOnEnv(),
    placementQueryConfig: {
      brand: getBrandBasedOnEnv(),
      containers: [
        {
          id: "SBK_PROMOHUB",
        },
      ],
    },
    routeConfig: defaultRoutes,
    environment: envMode,
  });

  useEffect(() => {
    initLogging();
  }, []);

  useEffect(() => {
    if (debugEnvironments.includes(import.meta.env.MODE)) {
      if (localStorage.getItem(LOCAL_STORAGE_KEY)) {
        setConfig({
          ...JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)),
          placementQueryConfig: JSON.parse(
            JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
              ?.placementQueryConfig
          ),
          environment: envMode,
        });
      }
    }
  }, [envMode]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const geoToken = urlParams.get("geoToken");
    const appName = urlParams.get("appName");
    const containerId = urlParams.get("containerId");
    const token = urlParams.get("authToken");
    const region = urlParams.get("region");
    const darkMode = urlParams.get("darkMode") === "true";
    if (appName) {
      if (!validateQueryParams(appName, containerId)) {
        return;
      }
      const baseUrl = getBaseUrlBasedOnAppName(appName);
      const papiBaseUrl = getPapiBaseUrlBasedOnAppName(appName);
      const newPlacementQueryConfig = {
        brand: getBrandBasedOnEnv(),
        containers: [
          {
            id: containerId,
          },
        ],
      };

      setConfig((prevConfig: AppConfig) => ({
        ...prevConfig,
        baseUrl: baseUrl,
        papiBaseUrl: papiBaseUrl,
        placementQueryConfig: newPlacementQueryConfig,
        environment: envMode,
        darkMode,
      }));

      setCookie(AUTH_TOKEN_COOKIE_NAME, validateAuthToken(token) ? token : "");

      const validatedRegion = validateSportsbookRegion(region) ? region : "";
      setCookie(X_REGION_NAME, validatedRegion);
      setCookie(LOCATION_COOKIE_NAME, validatedRegion);
    }

    setConfig((prevConfig) => ({
      ...prevConfig,
      geoToken,
      environment: envMode,
    }));
  }, []);

  usePostMessageListener((data: AppConfig) => {
    if (data) {
      data.baseUrl = getBaseUrlBasedOnAppName(data.appName);
      data.papiBaseUrl = getPapiBaseUrlBasedOnAppName(data.appName);
      setConfig((prevConfig) => ({
        ...prevConfig,
        ...data,
        environment: envMode,
      }));
      setCookie(
        AUTH_TOKEN_COOKIE_NAME,
        validateAuthToken(data.authToken) ? data.authToken : ""
      );

      setForceRenderKey(Date.now());
    }
  }, import.meta.env.VITE_APP_ALLOWED_ORIGINS.split(",") as string[]);

  const configValue = useMemo(
    () => ({
      config,
      setConfig,
    }),
    [config]
  );

  return (
    <AppConfigContext.Provider value={configValue}>
      <div key={forceRenderKey}>{children}</div>
    </AppConfigContext.Provider>
  );
};
