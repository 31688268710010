if (!Error.captureStackTrace) {
    Error.captureStackTrace = function(error: any) {
        if (typeof error.stack === 'undefined') {
            try {
                throw new Error(error.message);
            } catch (err) {
                error.stack = err.stack;
            }
        }
    };
}
