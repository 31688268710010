import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { ThemeProvider } from '@fanduel/xpg-promohub-components'
import { AppConfigProvider } from '@/providers/AppConfigProvider'
import { DebugContextProvider } from '@/contexts/DebugContext'
import { DebugButton } from '@/components/DebugButton'
import { DebugMenuModal } from '@/components/DebugMenuModal'
import { router } from '@/config/routes'
import { ErrorFallback } from './views/ErrorFallback'
import { debugEnvironments } from './utils/helpers'

export const App = () => {

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={console.error}>
            <DebugContextProvider>
                <AppConfigProvider>
                    <ThemeProvider>
                        <>
                            <RouterProvider router={createBrowserRouter(router)} />
                            {debugEnvironments.includes(import.meta.env.MODE) && (
                                <>
                                    <DebugButton />
                                    <DebugMenuModal />
                                </>
                            )}
                        </>
                    </ThemeProvider>
                </AppConfigProvider>
            </DebugContextProvider>
        </ErrorBoundary>

    )
}
