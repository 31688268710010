import {
  AppName,
  RouteHandlerTypes,
  LocationConfig,
  RouteHandler,
} from '@fanduel/xpg-promohub-library'
import React, {useMemo, useState} from 'react'
import { AUTH_TOKEN_COOKIE_NAME, LOCAL_STORAGE_KEY } from '../constants'
import { isNullOrUndefined, setCookie, validateAuthToken } from '../utils/helpers'
import { initialState, defaultState } from './constants'

export interface DebugOptions {
  onOpen(): void
  onClose(): void
  updateConfig(newDebugState: Partial<DebugState>): void
  clearConfig(): void
}

export interface DebugState {
  isOpen: boolean
  appName: AppName
  baseUrl: string
  papiBaseUrl: string
  locationConfig: LocationConfig
  placementQueryConfig: string
  routeConfig: {
    routes: Record<RouteHandlerTypes, RouteHandler>
  }
  authToken: string
  isLoggedIn: boolean
}

const initialContext: DebugOptions & DebugState = {
  ...initialState,
  onClose: () => null,
  onOpen: () => null,
  updateConfig: () => null,
  clearConfig: () => null,
}

export const DebugContext = React.createContext<DebugOptions & DebugState>(
  initialContext
)

export const DebugContextProvider: React.FC<any> = ({ children }) => {
  const [debugState, setDebugState] = useState<DebugState>(initialState)
  const debugContextValue = useMemo(() => {

  const onOpen = () => {
    setDebugState({ ...debugState, isOpen: true })
  }

  const onClose = () => {
    setDebugState({ ...debugState, isOpen: false })
  }

  const updateConfig = (newDebugState: Partial<DebugState>) => {
    const {
      baseUrl,
      papiBaseUrl,
      appName,
      placementQueryConfig,
      routeConfig,
      authToken,
      isLoggedIn,
      locationConfig,
    } = newDebugState

    setDebugState({
      ...debugState,
      isOpen: false,
      appName: appName || debugState.appName,
      baseUrl:  isNullOrUndefined(baseUrl) ? debugState.baseUrl : baseUrl,
      papiBaseUrl:  isNullOrUndefined(papiBaseUrl) ? debugState.papiBaseUrl : papiBaseUrl,
      locationConfig: locationConfig || debugState.locationConfig,
      placementQueryConfig:
        placementQueryConfig || debugState.placementQueryConfig,
      routeConfig: routeConfig || debugState.routeConfig,
      authToken: authToken || debugState.authToken,
      isLoggedIn: isLoggedIn || debugState.isLoggedIn,
    })

    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(
        {
          isOpen: false,
          appName: appName || debugState.appName,
          baseUrl: isNullOrUndefined(baseUrl) ? debugState.baseUrl : baseUrl,
          locationConfig: locationConfig || debugState.locationConfig,
          placementQueryConfig: isNullOrUndefined(placementQueryConfig)
            ? debugState.placementQueryConfig
            : placementQueryConfig,
          routeConfig: routeConfig || debugState.routeConfig,
          authToken: isNullOrUndefined(authToken)
            ? debugState.authToken
            : authToken,
          isLoggedIn: isNullOrUndefined(isLoggedIn)
            ? debugState.isLoggedIn
            : isLoggedIn,
        },
        undefined,
        2
      )
    )
    setCookie(AUTH_TOKEN_COOKIE_NAME, validateAuthToken(authToken) ? authToken : "")

    window.location.reload()
  }

  const clearConfig = () => {
    updateConfig(defaultState)
  }
    return {
      onOpen,
      onClose,
      updateConfig,
      clearConfig,
      ...debugState,
    };
  }, [debugState]);

  return (
    <DebugContext.Provider
      value={debugContextValue}
    >
      {children}
    </DebugContext.Provider>
  )
}
