import { AppName, IStep, RouteHandler, RouteHandlerTypes } from '@fanduel/xpg-promohub-library'

export const LOCAL_STORAGE_KEY = 'debugConfig'

export const AUTH_TOKEN_COOKIE_NAME = 'X-Auth-Token'
export const LOCATION_COOKIE_NAME = 'X-Cpp-Region'
export const X_REGION_NAME = 'X-Sportsbook-Region'
export const DEFAULT_LOCATION = { value: 'MI' }
export const DEFAULT_API_ENDPOINT = 'https://promos-api.fanduel.nxt.fndl.dev'
export const DEFAULT_LANDING_PAGE_IMAGE =
  'https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/default/landing-page.jpg'

export const DEFAULT_PROMO_HUB_IMAGE =
  'https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/default/promo-hub.jpg'

export const DEFAULT_CONTAINER = {
  id: 'Default-Fanduel',
  page: 1,
  context: {
    products: ['FANTASY', 'SPORTSBOOK', 'CASINO'],
  },
}

export const DEFAULT_QUERY_ENDPOINT = {
  brand: 'FANDUEL',
  containers: [DEFAULT_CONTAINER],
}

export const OPT_IN_WITH_OPTIONS_STEP: IStep = {
  action: 'Choose one of the following options to opt in to the promotion',
  completed: false,
  status: 'WAITING',
}

export const EMPTY_STATE = {
  title: "We're all out.",
  message: 'We are always updating our promotions check back soon.',
}

export const DESKTOP = 'desktop'
export const RGI = 'rgi'
export const REGION_PARAM_KEY = 'region'
export const INCENTIVE_GAMES_RGI = 'incentive_games'
export const THEME_PARAM = 'theme'

export const getQaUrl = (appName: AppName) => {
  switch (appName) {
    case AppName.STARDUST_CASINO:
      return 'https://promos-api.stardustcasino.fanduel.nxt.fndl.dev'
    case AppName.MOHEGANSUN_CASINO:
      return 'https://promos-api.mohegansun.fanduel.nxt.fndl.dev'
  }
  return 'https://promos-api.casino.fanduel.nxt.fndl.dev'
}

export const BASE_URL_BTNS = (appName: AppName) => {
  return [
    {
      id: 1,
      label: 'Local',
      url: 'http://localhost:5005',
    },
    {
      id: 2,
      label: `${appName} QA`,
      url: getQaUrl(appName),
    },
  ]
}

export const defaultRoutes: {routes: Record<RouteHandlerTypes, RouteHandler>} = {
  routes: {
    [RouteHandlerTypes.LOGIN]: { href: '/login', label: 'Login' },
    [RouteHandlerTypes.VIEW_PROMOTION]: {
      href: '/:promoCode',
      label: 'Promotion',
    },
    [RouteHandlerTypes.JOIN]: { href: '/join', label: 'Join' },
    [RouteHandlerTypes.ROOT]: { href: '/', label: 'Demo' },
    [RouteHandlerTypes.PROMOTIONS]: {
      href: '/',
      label: 'Promotions',
    },
    [RouteHandlerTypes.LEADERBOARD]: {
      href: '/leaderboard',
      label: 'Leaderboard',
    }
  },
}

export const US_REGIONS = [
  "ak",
  "al",
  "ar",
  "az",
  "ca",
  "co",
  "ct",
  "dc",
  "de",
  "fd",
  "fl",
  "ga",
  "hi",
  "ia",
  "id",
  "il",
  "in",
  "ks",
  "ky",
  "la",
  "ma",
  "md",
  "me",
  "mi",
  "mn",
  "mo",
  "mr",
  "ms",
  "mt",
  "nc",
  "nd",
  "ne",
  "nh",
  "nj",
  "nm",
  "nv",
  "ny",
  "oh",
  "ok",
  "or",
  "pa",
  "pr",
  "ri",
  "sc",
  "sd",
  "tn",
  "tx",
  "ut",
  "va",
  "vt",
  "wa",
  "wi",
  "wv",
  "wy",
] as const

export type RegionUs = typeof US_REGIONS[number]

export const CANADA_REGIONS = [
  "on",
] as const

export type RegionCan = typeof CANADA_REGIONS[number]
