import { EMPTY_STATE } from '@/constants';
import { ErrorScreen } from '@fanduel/xpg-promohub-components'
import React from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

export const ErrorFallback: React.FC = () => {
    const error = useRouteError();
    if(isRouteErrorResponse(error)) {
        console.error(error);
    }

    return <ErrorScreen message={EMPTY_STATE.message} title={EMPTY_STATE.title}></ErrorScreen>;
};
