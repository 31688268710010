import { ErrorFallback } from "@/views/ErrorFallback";
import { RouteObject } from "react-router-dom";

const generateRoute = (
  path: string,
  lazyComponent: () => Promise<{ default: React.ComponentType }>
) => ({
  path,
  index: true,
  async lazy() {
    const { default: Component } = await lazyComponent();
    return { Component };
  },
  errorElement: <ErrorFallback />,
});

export const router = [
  generateRoute("/", () => import("@/views/PromosList")),
  generateRoute("/xsell", () => import("@/views/PromosList")),
  generateRoute("/xsell/native", () => import("@/views/PromosList")),
  generateRoute("/moheganxsell", () => import("@/views/PromosList")),
  generateRoute("/moheganxsell/native", () => import("@/views/PromosList")),
  generateRoute("/:promocode", () => import("@/views/PromoDetail")),
  generateRoute("/promotion", () => import("@/views/PromoRedirect")),
  generateRoute("/fanduel-edge", () => import("@/views/FanDuelPro")),
  {
    path: "*",
    async lazy() {
      return {
        Component: () => <>not found</>,
      };
    },
  },
] as RouteObject[];
