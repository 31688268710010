import { useEffect } from 'react'

type MessageCallback = (data: any) => void

export const usePostMessageListener = (callback: MessageCallback, allowedOrigins?: string[]): void => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      localStorage.setItem("eventTest", JSON.stringify(event.data))
      console.log("debug event:", JSON.stringify(event.data))
      if (allowedOrigins?.includes(event.origin)) {
        if (event.data?.type === 'APP_CONFIG') {
          callback?.(event.data?.payload)
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [callback, allowedOrigins])
}