import { LOCAL_STORAGE_KEY, AUTH_TOKEN_COOKIE_NAME, defaultRoutes } from "@/constants"
import { getBaseUrlBasedOnEnv, getPapiBaseUrlBasedOnEnv } from "@/utils/helpers"
import { AppName } from "@fanduel/xpg-promohub-library"
import Cookies from "js-cookie"
import { DebugState } from "./DebugContext"

export const defaultState: DebugState = {
  isOpen: false,
  appName: AppName.FANDUEL_CASINO,
  baseUrl: import.meta.env.VITE_APP_API_ENDPOINT,
  papiBaseUrl: import.meta.env.VITE_APP_PAPI_ENDPOINT,
  locationConfig: {
    value: 'WV',
  },
  placementQueryConfig: JSON.stringify(
    {
      brand: 'FANDUEL',
      containers: [
        {
          id: '678',
        },
      ],
    },
    undefined,
    2
  ),
  routeConfig: defaultRoutes,
  authToken: '',
  isLoggedIn: false,
}
  
export const initialState: DebugState = JSON.parse(
  localStorage.getItem(LOCAL_STORAGE_KEY)
) || {
  ...defaultState,
  baseUrl: getBaseUrlBasedOnEnv(),
  papiBaseUrl: getPapiBaseUrlBasedOnEnv(),
  placementQueryConfig: JSON.stringify(
    {
    brand: 'FANDUEL',
    containers: [
      {
      id: 'SBK_PPH',
      },
    ],
    },
    undefined,
    2
  ),
  authToken: Cookies.get(AUTH_TOKEN_COOKIE_NAME) || '',
  isLoggedIn: !!Cookies.get(AUTH_TOKEN_COOKIE_NAME),
}